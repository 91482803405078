import { css } from 'styled-components';

export const requesterContentCSS = css`
    padding-top: 20px;
`;

export const close = css`
    position: static;
    float: right;
`;

export const springMd = css`
    max-width: 780px;
    margin: 0 auto;
    min-width: 300px;
    padding: 0 10px;

    @media only screen and (max-width: 1024px) {
        padding: 0 20px;
    }

    @media only screen and (max-width: 639px) {
        padding: 0 10px;
    }
`;