import styled, { css } from 'styled-components';

export const StyledBody = styled.div`
  h1 {
    margin: 25px 0 35px;

    @media (min-width: 768px) {
        margin: 20px 0 60px;
    }
  }

  p {
    margin: 35px 0;
  }
`;

export const transparent = css`
  background-color: transparent;
`;