import React from 'react'; 
import { css } from 'styled-components';
import CtaButtonRow from '../../Elements/CtaButtons';
import { SectionRequesterProps } from './types/SectionRequester.types';
import {
  requesterContentCSS,
  close,
  springMd
} from './styles/SectionRequester.styles';

const SectionRequester = (props: SectionRequesterProps) => {
  const { data } = props;
  const { backgroundColor, textColor, visibility } = data;
  let buttons: any;
  const description = data?.description?.description;
  const buttonsRows = data?.content?.find(content => content.buttons);
  const buttonRowAlignment = buttonsRows?.buttonRowAlignment;

  if (buttonsRows !== undefined) {
    buttons = buttonsRows.buttons;
  }

  const initialVisibility = visibility === 'visible' ? 'block' : 'none';

  const requesterWrapper = css`
    top: 0;
    width: 100%;
    padding: 20px;

    background: ${backgroundColor};
    color: ${textColor};
    display: ${initialVisibility};
  `;

  return (
    <div css={requesterWrapper} id="vh-section-requester">
      <a href="#" id="btn_close_requester" css={close}>
        <svg
          height="32"
          width="32"
          viewBox="0 0 192 192"
          xmlns="<http://www.w3.org/2000/svg>"
        >
          <path
            data-name="&lt;Pfad>"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="8"
            d="M48 144l96-96M144 144L48 48"
          />
          <path fill="none" d="M0 0h192v192H0z" />
        </svg>
      </a>

      <div css={springMd}>
        <div css={requesterContentCSS}>
          {description}
          <div>
            {buttons && (
              <CtaButtonRow
                buttons={buttons}
                buttonRowAlignment={buttonRowAlignment}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionRequester;
