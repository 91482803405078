import React, { useEffect, useRef, useState } from 'react';
import { getSrc } from 'gatsby-plugin-image';
import { createGlobalStyle } from 'styled-components';
import MainLayout from '../layouts/MainLayout';
import makePageSections from './page-builder-helpers';
import { SEO } from '../components/SEO/SEO';
import { graphql, useStaticQuery } from 'gatsby';
import SectionRequester from '../components/pageBuilder/Sections/SectionRequester';
import useDocumentPathname from '../hooks/useDocumentPathname';
import { addSeoHeadContent, addSeoBodyContent } from '../helpers/SeoHelpers';
import SaveArticleModal from '../components/SaveArticleModal/SaveArticleModal';

const queryHomePageImageUrl = graphql`
  {
    contentfulPageBuilder(slug: { eq: "/" }) {
      __typename
      contentful_id
      sections {
        __typename
        ... on ContentfulSectionHeroWithTextAndCta {
          mainImage {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
          responsiveImageCollection {
            imageTitle {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
            isMainImage
          }
        }
      }
    }
  }
`;

const PageBuilder = ({ pageContext, location }) => {
  const {
    pageBuilderData,
    recaptchaToken,
    readingTimeMinuteLabel,
    displaySaveIcon,
    saveLabelText,
    saveArticleModalContent,
  } = pageContext;
  const {
    seoTitle,
    seoDescription,
    metadata,
    scripts = [],
    sections,
    nodeLocale,
    oddColor = '',
    evenColor = '',
  } = pageBuilderData;

  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [articleSlug, setArticleSlug] = useState('');

  const onSaveIconClickHandler = slug => {
    setIsSaveModalOpen(true);
    setArticleSlug(slug);
  };

  const onCloseModalHandler = () => {
    setIsSaveModalOpen(false);
    setArticleSlug('');
  };

  let odd = '',
    even = '';
  odd = oddColor.toLowerCase() === 'white' ? 'var(--white)' : 'var(--gallery)';
  even =
    evenColor.toLowerCase() === 'white' ? 'var(--white)' : 'var(--gallery)';

  const OddStyles = createGlobalStyle`
    ${'' /* Vodafone Brand Colors */}
    #scroll-root>div.section:nth-of-type(even) {
      background: ${odd};
    }
  `;
  const EvenStyles = createGlobalStyle`
    ${'' /* Vodafone Brand Colors */}
    #scroll-root>div.section:nth-of-type(odd) {
      background: ${even};
    }
  `;

  const pageSections = makePageSections(
    sections,
    recaptchaToken,
    location,
    seoTitle,
    nodeLocale,
    readingTimeMinuteLabel,
    displaySaveIcon,
    saveLabelText,
    onSaveIconClickHandler
  );

  const CONTENTFUL_SECTION_REQUESTER = 'ContentfulSectionRequester';
  const requesterSections = sections.map(section => {
    if (section.__typename === CONTENTFUL_SECTION_REQUESTER) {
      return <SectionRequester key={section.id} data={section} />;
    }
  });

  const { contentfulPageBuilder } = useStaticQuery(queryHomePageImageUrl);
  let mainImageUrl = getMainImage(sections);
  const indexPageMainImageUrl = getMainImage(contentfulPageBuilder.sections);

  // For the og:image and twitter:image, Get either the current page banner
  // image or the index page image.
  mainImageUrl = mainImageUrl || indexPageMainImageUrl;

  const pageBuilderSeo = {
    metaData: metadata,
    scripts,
    language: 'no_content',
  };
  const seoHead = useRef(addSeoHeadContent(pageBuilderSeo));
  const pathname = useDocumentPathname();

  useEffect(() => {
    if (window.seoPageChange) {
      try {
        window.seoPageChange();
      } catch (e) {
        console.error(e);
      }
    }
  }, [pathname]);

  useEffect(() => {
    addSeoBodyContent(pageBuilderSeo);
  }, []);

  return (
    <>
      {requesterSections}
      <MainLayout locationPathname={location.pathname}>
        {saveArticleModalContent && (
          <SaveArticleModal
            header={saveArticleModalContent.header}
            bodyText={saveArticleModalContent.bodyText}
            buttonText={saveArticleModalContent.buttonText}
            buttonUrl={saveArticleModalContent.buttonUrl}
            footerText={saveArticleModalContent.footerText}
            footerLinkText={saveArticleModalContent.footerLinkText}
            footerLinkUrl={saveArticleModalContent.footerLinkUrl}
            articleSlug={articleSlug}
            isOpen={isSaveModalOpen}
            onClose={onCloseModalHandler}
          />
        )}
        {seoHead.current}
        <SEO
          metadata={[
            ...[
              {
                content: seoTitle,
                metadataTag: 'title',
              },
              {
                name: 'description',
                content: seoDescription,
                metadataTag: 'meta',
              },
              {
                name: 'og:type',
                content: 'website',
                metadataTag: 'meta',
              },
              {
                name: 'og:title',
                content: seoTitle,
                metadataTag: 'meta',
              },
              {
                name: 'og:description',
                content: seoDescription,
                metadataTag: 'meta',
              },
              {
                name: 'og:image',
                content: `${mainImageUrl}`,
                metadataTag: 'meta',
              },
              {
                name: 'twitter:title',
                content: seoTitle,
                metadataTag: 'meta',
              },
              {
                name: 'twitter:description',
                content: seoDescription,
                metadataTag: 'meta',
              },
              {
                name: 'twitter:image',
                content: `${mainImageUrl}`,
                metadataTag: 'meta',
              },
            ],
          ]}
        />
        {pageSections}
        {oddColor !== 'N/A' && <OddStyles />}
        {evenColor !== 'N/A' && <EvenStyles />}
      </MainLayout>
    </>
  );
};

function getMainImage(sections) {
  // Go through the sections and return the first image url that can be used as the
  // og:image/twitter:image url.
  let mainImageUrl = null;
  sections.some(section => {
    switch (section.__typename) {
      case 'ContentfulSectionHeroWithTextAndCta':
        // return the mainImage in the responsive set.
        section.responsiveImageCollection.some(image => {
          if (image.isMainImage) {
            mainImageUrl = getSrc(image.imageTitle);
          }
          return image.isMainImage;
        });
        // If we haven't found an image in the responsive set, use the mainImage.
        if (!mainImageUrl) {
          mainImageUrl = getSrc(section.mainImage)?.split('?')[0] ?? null;
        }
        break;

      default:
      // Do nothing - not a section with an image so 'don't care'.
    }
    return mainImageUrl !== null;
  });
  return mainImageUrl;
}

export default PageBuilder;
